<template>
  <div>
    <!-- Navbar -->
    <nav
      class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent"
    >
      <div class="container">
        <div class="navbar-wrapper">
          <div class="navbar-toggle">
            <button type="button" class="navbar-toggler">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <router-link to="/login" class="navbar-brand"
            ><img
              :src="require(`@/assets/images/enroll_logo_light.svg`)"
              alt="Enroll Talent"
              height="60px"
          /></router-link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navigation"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/signup" class="nav-link">
                <i class="nc-icon nc-book-bookmark"></i>
                <translate>Register</translate>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/login" class="nav-link">
                <i class="nc-icon nc-tap-01"></i>
                <translate>Login</translate>
              </router-link>
            </li>
            <li class="nav-item">
              <LanguageSwitcher class="text-white" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <div class="wrapper wrapper-full-page">
      <div
        class="full-page section-image min-vh-100"
        :style="
          'background-image: url(\'' +
            require(`@/assets/images/cover/fullpage.jpg`) +
            '\');'
        "
      >
        <div class="content">
          <div class="container">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from "@/layouts/components/LanguageSwitcher";
export default {
  components: { LanguageSwitcher }
};
</script>
